<template>
  <div>
    <div class="f-c-b">
      <el-page-header @back="backPrivious" content="职位发布"></el-page-header>
      <router-link v-show="enterpriseStateInfo.userPermission.p_m == 'W'" :to="{
        name: 'RecruitPublish', query: {
          key: $UrlEncode.encode(JSON.stringify({
            type: 'publish',
          }))
        }
      }">
        <el-button type="primary">发布</el-button>
      </router-link>
    </div>
    <div class="list">
      <PositionItem v-for="(item, index) of list" :Jobitem="item" :key="index"></PositionItem>
    </div>
    <Pagination :total="total" :pageNum.sync="currentPage" :limit.sync="size" :layout="layout" @pagination="fetchData" />
  </div>
</template>

<script>
import { PaginationRetention } from '@/mixins/PaginationRetentionMixin';
export default {
  name: 'RecruitList',
  inject: ['reload'],
  mixins: [PaginationRetention],
  components: {
    PositionItem: () => import('./components/PositionItem.vue')
  },
  data() {
    return {
      list: [],
      enterpriseStateInfo: this.$getStorage('enterpriseStateInfo'),
    }
  },
  methods: {
    getDate() {
      this.forumRecruitEnterpriseArticleList(this.paging, this.pagingSize)
    },
    // 获取参数
    getParams(page, size) {
      let that = this;
      return {
        postUserId: parseInt(that.$store.state.userId),
        token: that.$getStorage('token'),
        page,
        size
      }
    },
    //  企业内部职位招聘帖子列表接口
    forumRecruitEnterpriseArticleList(page = this.page, size = this.size) {
      let that = this;
      that.$http.forumRecruitEnterpriseArticleList(that.getParams(page, size)).then(res => {
        if (res.code == 200) {
          res.data.records.forEach(item => {
            item.expirationDate1 = item.expirationDate ? item.expirationDate.split(' ')[0] : ''
          })
          that.list = res.data.records
          that.currentPage = res.data.current;//当前页数
          that.total = res.data.total
        }
      })
    }
  },
}

</script>

<style lang="less" scoped>
.list {
  margin-top: 30px;
}
</style>
