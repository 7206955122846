var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"f-c-b"},[_c('el-page-header',{attrs:{"content":"职位发布"},on:{"back":_vm.backPrivious}}),_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.enterpriseStateInfo.userPermission.p_m == 'W'),expression:"enterpriseStateInfo.userPermission.p_m == 'W'"}],attrs:{"to":{
      name: 'RecruitPublish', query: {
        key: _vm.$UrlEncode.encode(JSON.stringify({
          type: 'publish',
        }))
      }
    }}},[_c('el-button',{attrs:{"type":"primary"}},[_vm._v("发布")])],1)],1),_c('div',{staticClass:"list"},_vm._l((_vm.list),function(item,index){return _c('PositionItem',{key:index,attrs:{"Jobitem":item}})}),1),_c('Pagination',{attrs:{"total":_vm.total,"pageNum":_vm.currentPage,"limit":_vm.size,"layout":_vm.layout},on:{"update:pageNum":function($event){_vm.currentPage=$event},"update:page-num":function($event){_vm.currentPage=$event},"update:limit":function($event){_vm.size=$event},"pagination":_vm.fetchData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }